/**
 * @generated SignedSource<<da73cf2aa345815db76ec1c75b2e4548>>
 * @relayHash 15034cc6c43338be0ce5fea7e5f53f56
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/505.0.0-2025-04-09T15:00:40.730Z/ContactDesignerModalQuery

import { ConcreteRequest } from 'relay-runtime';
export type ContactDesignerModalQuery$variables = {
  buyerId: string;
  hasBuyerId: boolean;
};
export type ContactDesignerModalQuery$data = {
  readonly viewer: {
    readonly user?: {
      readonly profile: {
        readonly email: string | null;
      } | null;
    } | null;
  };
};
export type ContactDesignerModalQuery = {
  response: ContactDesignerModalQuery$data;
  variables: ContactDesignerModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buyerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hasBuyerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "buyerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Profile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactDesignerModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasBuyerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactDesignerModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasBuyerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/505.0.0-2025-04-09T15:00:40.730Z/ContactDesignerModalQuery",
    "metadata": {},
    "name": "ContactDesignerModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d40c434cd002aeba522f2f00f82b1bb9";

export default node;
