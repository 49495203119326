/**
 * @generated SignedSource<<530b1e2b3255c308bb90d9779282049e>>
 * @relayHash 4e1ef47e95cf0987cb9b872e68324522
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/505.0.0-2025-04-09T15:00:40.730Z/profileAddressMutation

import { ConcreteRequest } from 'relay-runtime';
export type FirmDesignProfileAddressMutationInput = {
  city?: string | null;
  clientMutationId?: string | null;
  country?: string | null;
  line1?: string | null;
  line2?: string | null;
  phoneNumber?: string | null;
  serviceId: string;
  state?: string | null;
  zipCode?: string | null;
};
export type profileAddressMutation$variables = {
  input: FirmDesignProfileAddressMutationInput;
};
export type profileAddressMutation$data = {
  readonly firmDesignProfileAddressMutation: {
    readonly tradeFirm: {
      readonly publicProfile: {
        readonly address: {
          readonly city: string | null;
          readonly country: string | null;
          readonly state: string | null;
          readonly zipCode: string | null;
        } | null;
        readonly displayLocation: string | null;
      } | null;
    } | null;
  } | null;
};
export type profileAddressMutation = {
  response: profileAddressMutation$data;
  variables: profileAddressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayLocation",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zipCode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "profileAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileAddressMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileAddressMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeFirmPublicProfileType",
                "kind": "LinkedField",
                "name": "publicProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "profileAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileAddressMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileAddressMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeFirmPublicProfileType",
                "kind": "LinkedField",
                "name": "publicProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/505.0.0-2025-04-09T15:00:40.730Z/profileAddressMutation",
    "metadata": {},
    "name": "profileAddressMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "0470de274dd24ba372ba6644c9bc6d18";

export default node;
