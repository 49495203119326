/**
 * @generated SignedSource<<eaf87299ccbed1f867b267b9811548f3>>
 * @relayHash 00251b2c76454c05e376088f10b8c83b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/505.0.0-2025-04-09T15:00:40.730Z/profileArticlesMutation

import { ConcreteRequest } from 'relay-runtime';
export type FirmDesignProfileArticleMutationInput = {
  articles: ReadonlyArray<FirmDesignProfileArticleInput | null>;
  clientMutationId?: string | null;
  serviceId: string;
};
export type FirmDesignProfileArticleInput = {
  description?: string | null;
  photoId?: string | null;
  title?: string | null;
  url?: string | null;
};
export type profileArticlesMutation$variables = {
  input: FirmDesignProfileArticleMutationInput;
};
export type profileArticlesMutation$data = {
  readonly firmDesignProfileArticleMutation: {
    readonly tradeFirm: {
      readonly publicProfile: {
        readonly articles: ReadonlyArray<{
          readonly description: string | null;
          readonly photo: {
            readonly path: string | null;
            readonly serviceId: string | null;
          } | null;
          readonly title: string | null;
          readonly webLink: {
            readonly url: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type profileArticlesMutation = {
  response: profileArticlesMutation$data;
  variables: profileArticlesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "WebLink",
  "kind": "LinkedField",
  "name": "webLink",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "profileArticlesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileArticleMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileArticleMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeFirmPublicProfileType",
                "kind": "LinkedField",
                "name": "publicProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DesignFirmArticleType",
                    "kind": "LinkedField",
                    "name": "articles",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Photo",
                        "kind": "LinkedField",
                        "name": "photo",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "profileArticlesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileArticleMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileArticleMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeFirmPublicProfileType",
                "kind": "LinkedField",
                "name": "publicProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DesignFirmArticleType",
                    "kind": "LinkedField",
                    "name": "articles",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Photo",
                        "kind": "LinkedField",
                        "name": "photo",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/505.0.0-2025-04-09T15:00:40.730Z/profileArticlesMutation",
    "metadata": {},
    "name": "profileArticlesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "091e92845465523c8493e4b964db5b4b";

export default node;
